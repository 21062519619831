<template>
  <section class="app-ecommerce-details">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="product === undefined"
    >
      <h4 class="alert-heading">
        Error fetching product data
      </h4>
      <div class="alert-body">
        No item found with this item slug. Check
        <b-link
          class="alert-link"
          :to="{ name: 'products-index'}"
        >
          Shop
        </b-link>
        for other items.
      </div>
    </b-alert>

    <!-- Content -->
    <b-row>
      <b-col
        cols="12"
        md="7"
      >
        <b-card>
          <div class="card_top mb-2">
            <b-img
              fluid
              rounded
              :src="$options.filters.mediaUrl(product, 'avatar', '320x320')"
            />
            <h1>{{ $options.filters.transl(product, 'title') }}</h1>
            <div class="badges">
              <b-badge
                v-b-tooltip.hover.v-primary
                :title="$t('catalog.item.labels.total_views')"
                variant="primary"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-25"
                />
                <span>{{ product.views }}</span>
              </b-badge>
              <b-badge
                v-b-tooltip.hover.v-primary
                :title="$t('catalog.item.labels.total_favorites')"
              >
                <feather-icon
                  icon="HeartIcon"
                  class="mr-25"
                />
                <span>{{ product.favorites }}</span>
              </b-badge>
            </div>
          </div>
          <div class="link mb-1">
            <a
              class="text-primary"
              :href="product.link"
              target="_blank"
            >
              {{ product.link }}
            </a>
          </div>
          <b-card-text class="item-description mb-2">
            {{ $options.filters.transl(product, 'description') }}
          </b-card-text>
          <b-row class="data-cols">
            <b-col cols="4">
              <div class="design-planning">
                <p class="card-text mb-25">
                  {{ $t('catalog.item.labels.language') }}
                </p>
                <h6 class="mb-0">
                  <b-img
                    class="lang"
                    :src="`/language/${product.language}.svg`"
                    :title="$t(`general.languages.${product.language}`)"
                  />
                  {{ $t(`general.languages.${product.language}`) }}
                </h6>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="design-planning">
                <p class="card-text mb-25">
                  {{ $t('catalog.item.labels.category') }}
                </p>
                <h6
                  v-for="(category, cin) in product.categories"
                  :key="cin + '_' + 'cat'"
                  class="mb-0"
                >
                  {{ $options.filters.transl(category, 'title') }}
                </h6>
              </div>
            </b-col>
            <b-col cols="4">
              <div class="design-planning">
                <p class="card-text mb-25">
                  {{ $t('catalog.item.labels.type') }}
                </p>
                <h6 class="mb-0">
                  {{ $t(`general.privacytype.${product.private}`) }}
                </h6>
              </div>
            </b-col>
            <b-col cols="12">
              <div class="design-planning">
                <ul class="item-stickers">
                  <li
                    v-for="(sticker,sin) in product.stickers"
                    :key="sin"
                    v-b-tooltip.hover.v-primary
                    :title="$options.filters.transl(sticker, 'title')"
                    class="item-stickers-item"
                  >
                    <b-img
                      fluid
                      :src="$options.filters.mediaUrl(sticker, 'avatar', 'base')"
                    />
                  </li>
                </ul>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        md="5"
      >
        <b-card class="text-center">
          <h2>
            {{ $t('catalog.price.descriptions.buy') }}
          </h2>
          <!-- Product Actions -->
          <div class="item-options text-center">
            <p class="card-text mb-25">
              {{ $t('catalog.price.types.title') }}
            </p>
            <b-dropdown
              :text="$t(`catalog.price.types.${priceType}`)"
              right
              variant="outline-primary"
            >
              <b-dropdown-item
                v-for="price in product.prices"
                :key="price.type"
                @click="priceType = price.type"
              >
                {{ $t(`catalog.price.types.${price.type}`) }}
              </b-dropdown-item>
            </b-dropdown>

            <b-card-text class="item-description mt-2 mb-2">
              {{ $t(`catalog.price.descriptions.${priceType}`) }}
            </b-card-text>

            <div class="mb-2 item-wrapper">
              <div
                v-if="calcDiscount"
                class="item-cost"
              >
                <del class="item-price">
                  {{ calcPrice }}
                </del>
                <h4 class="item-price text-danger">
                  {{ calcDiscount }}
                </h4>
              </div>
              <div
                v-else
                class="item-cost"
              >
                <h4 class="item-price">
                  {{ calcPrice }}
                </h4>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height small_in_1200_1400">
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          v-b-tooltip.hover.v-primary
          color="info"
          icon="UsersIcon"
          :statistic="$options.filters.numberFormat(getHistory.subscribers)"
          :statistic-title="$t('catalog.item.labels.subscribers')"
          :title="$t('catalog.item.descriptions.subscribers')"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          v-b-tooltip.hover.v-primary
          color="warning"
          icon="EyeIcon"
          :statistic="$options.filters.fFormat(getHistory.views)"
          :statistic-title="$t('catalog.item.labels.views')"
          :title="$t('catalog.item.descriptions.views')"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          v-b-tooltip.hover.v-primary
          color="danger"
          icon="ZapIcon"
          :statistic="$options.filters.numberFormat(getHistory.er) + '%'"
          :statistic-title="$t('catalog.item.labels.er')"
          :title="$t('catalog.item.descriptions.er')"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          v-b-tooltip.hover.v-primary
          color="primary"
          icon="DollarSignIcon"
          :statistic="$options.filters.moneyFormat(getHistory.cpv)"
          :statistic-title="$t('catalog.item.labels.cpv')"
          :title="$t('catalog.item.descriptions.cpv')"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          v-b-tooltip.hover.v-primary
          color="primary"
          icon="DollarSignIcon"
          :statistic="$options.filters.numberFormat(getHistory.cpv * 1000)"
          :statistic-title="$t('catalog.item.labels.cpm')"
          :title="$t('catalog.item.descriptions.cpm')"
        />
      </b-col>
      <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <statistic-card-vertical
          v-b-tooltip.hover.v-primary
          hide-chart
          color="danger"
          icon="TagIcon"
          :statistic="$options.filters.numberFormat(getHistory.mentions)"
          :statistic-title="$t('catalog.item.labels.mentions')"
          :title="$t('catalog.item.descriptions.mentions')"
        />
      </b-col>
    </b-row>

    <!-- Content -->
    <b-row class="small_in_1200_1400">
      <b-col
        cols="12"
        md="2"
      >
        <statistic-card-vertical
          hide-chart
          color="primary"
          icon="ShoppingCartIcon"
          :statistic="$options.filters.numberFormat(product.tasks_count)"
          :statistic-title="$t('catalog.item.labels.orders')"
        />
        <statistic-card-vertical
          hide-chart
          color="success"
          icon="ThumbsUpIcon"
          :statistic="$options.filters.numberFormat(product.rating)"
          :statistic-title="$t('catalog.item.labels.rating')"
        />
        <statistic-card-vertical
          hide-chart
          color="warning"
          icon="StarIcon"
          :statistic="$options.filters.numberFormat(product.stars)"
          :statistic-title="$t('catalog.item.labels.rate')"
        />
      </b-col>
      <b-col
        cols="12"
        md="5"
      >
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1">
                {{ $t('catalog.item.labels.graph') }}
              </b-card-title>
            </div>
            <!--/ title and subtitle -->
          </b-card-header>
          <b-card-body
            v-if="history"
            class="graph pl-0 pr-0 pb-0"
          >
            <vue-apex-charts
              type="area"
              height="444"
              :options="lineAreaChartSpline.chartOptions"
              :series="lineAreaChartSpline.series"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="5"
      >
        <statistic-card-vertical
          v-b-tooltip.hover.v-primary
          color="success"
          icon="MessageSquareIcon"
          :statistic="$options.filters.numberFormat(getHistory.posts)"
          :statistic-title="$t('catalog.item.labels.posts')"
          :title="$t('catalog.item.descriptions.posts')"
        />
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1">
                {{ context.activeYMD }} - {{ context.disabled ? 'занято' : 'вільно' }}
              </b-card-title>
            </div>
            <!--/ title and subtitle -->
          </b-card-header>
          <b-card-body class="pt-0 pl-0 pr-0 pb-0">
            <b-calendar
              block
              :date-info-fn="dateClass"
              :date-disabled-fn="dateDisabled"
              locale="uk"
              v-bind="labels.ua || {}"
              class="p-2"
              start-weekday="1"
              :hide-header="hideHeader"
              @context="onContext"
            />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- comment -->
    <b-row v-if="reviews && reviews.length">
      <b-col
        id="blogComment"
        cols="12"
        class="mt-2"
      >
        <h6 class="section-label">
          {{ $t('catalog.item.labels.reviews') }}
        </h6>
        <b-card
          v-for="review in reviews"
          :key="review.id"
        >
          <b-media no-body>
            <b-media-aside class="mr-75">
              <b-avatar
                size="lg"
                variant="light-success"
                :text="$options.filters.firstLetterAvatar(review.user.email)"
              />
            </b-media-aside>
            <b-media-body>
              <h6 class="font-weight-bolder mb-25">
                {{ $options.filters.hideEmail(review.user.email) }}
              </h6>
              <b-card-text>{{ review.created_at }}</b-card-text>
              <ul class="unstyled-list list-inline">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item"
                  :class="{'ml-25': star-1}"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="22"
                    :class="[{'fill-current': star <= review.rating}, star <= review.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
              </ul>
              <b-card-text>
                {{ review.description }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-card>
      </b-col>
    </b-row>

  </section>
</template>

<script>
// todo
import _ from 'lodash'
import GlobalMixin from '@mixins/GlobalMixin'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import VueApexCharts from 'vue-apexcharts'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  components: {
    StatisticCardVertical,
    VueApexCharts,
  },
  mixins: [GlobalMixin],
  data() {
    return {
      product: null,
      reviews: null,
      history: null,
      quantity: 1,
      priceType: '1day',
      lineAreaChartSpline: {
        series: [
          {
            name: 'Підписників',
            data: [1],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: false,
            curve: 'straight',
          },
          legend: {
            show: false,
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: [
              '7/12',
            ],
          },
          yaxis: {
            // opposite: isRtl
          },
          fill: {
            opacity: 0.8,
            type: 'solid',
          },
          tooltip: {
            shared: false,
          },
          colors: ['#2bdac7'],
        },
      },
      labels: {
        ua: {
          labelPrevYear: 'Попередній рік',
          labelPrevMonth: 'Попередній місяць',
          labelCurrentMonth: 'Теперішній місяць',
          labelNextMonth: 'Наступний місяць',
          labelNextYear: 'Наступний рік',
          labelToday: 'Сьогодні',
          labelSelected: 'Вибрано',
          labelNoDateSelected: 'Дату не вибрано',
          labelCalendar: 'Календар',
          labelNav: 'Навігація',
          labelHelp: 'Навігація',
        },
      },
      context: {
        activeYMD: null,
        disabled: false,
      },
      hideHeader: true,
    }
  },
  computed: {
    selectedPrice() {
      const vm = this
      return _.filter(this.product.prices, o => o.type === vm.priceType)
    },
    selectedDiscount() {
      const vm = this
      return _.filter(this.product.discounts, o => o.type === vm.priceType)
    },
    calcPrice() {
      return this.selectedPrice && this.selectedPrice.length ? this.$options.filters.moneyFormat(this.quantity * this.selectedPrice[0].price) : 0
    },
    calcDiscount() {
      return this.selectedDiscount && this.selectedDiscount.length ? this.$options.filters.moneyFormat(this.quantity * this.selectedDiscount[0].price) : 0
    },
    getHistory() {
      let h = {
        posts: 0,
        mentions: 0,
        subscribers: 0,
        views: 0,
        er: 0,
        cpv: 0,
      }
      const koef = this.koefOptions[this.priceType]
      if (this.product.historyLast) {
        h = {
          posts: this.product.historyLast.posts,
          mentions: this.product.historyLast.mentions,
          subscribers: this.product.historyLast.subscribers,
          views: this.product.historyLast.views * koef,
          er: ((this.product.historyLast.views * koef) / this.product.historyLast.subscribers) * 100,
          cpv: (this.selectedDiscount && this.selectedDiscount.length ? this.selectedDiscount[0].price : this.selectedPrice[0].price) / (this.product.historyLast.views * koef),
        }
      }
      return h
    },
    disabledDates() {
      let ret = []
      _.each(this.product.tasks, task => {
        if (task.started_at && task.ended_at) {
          ret = ret.concat(this.dtRange(task.started_at, task.ended_at))
        }
        if (task.started_at && !task.ended_at) {
          ret.push(task.started_at)
        }
        if (!task.started_at && task.ended_at) {
          ret.push(task.ended_at)
        }
      })
      return ret
    },
  },
  async beforeCreate() {
    this.$http.get(`/api/promo/products/${this.$route.params.slug}`)
      .then(response => {
        this.product = response.data.data
        this.product.isInWishlist = Object.values(JSON.parse(localStorage.getItem('wishlist')) || {}).filter(item => item.product.id === this.product.id).length
        this.priceType = this.product.prices[0].type
        this.$http.get(`/api/promo/reviews/?reviewable_type=Product&reviewable_id=${this.product.id}`).then(res => {
          if (res.status === 200) {
            this.reviews = res.data.data
          }
        })
        this.$http.get(`/api/promo/products/${this.product.id}/history`).then(res => {
          if (res.status === 200) {
            this.history = _.reverse(res.data.data)
            this.lineAreaChartSpline.series[0].data = _.map(this.history, h => h.subscribers)
            this.lineAreaChartSpline.chartOptions.xaxis.categories = _.map(this.history, h => h.created)
          }
        })
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$t('notifications.not_found'),
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
      })
  },
  methods: {
    dtRange(start, end) {
      start = new Date(start)
      end = new Date(end)
      const result = []
      while (start <= end) {
        const mm = ((start.getMonth() + 1) >= 10) ? (start.getMonth() + 1) : `0${start.getMonth() + 1}`
        const dd = ((start.getDate()) >= 10) ? (start.getDate()) : `0${start.getDate()}`
        const yyyy = start.getFullYear()
        result.push(`${yyyy}-${mm}-${dd}`)
        start = new Date(start.setDate(start.getDate() + 1))
      }
      return result
    },
    dateClass(ymd) {
      return _.includes(this.disabledDates, ymd) ? 'table-warning' : 'table-success'
    },
    dateDisabled(ymd) {
      return _.includes(this.disabledDates, ymd)
    },
    onContext(ctx) {
      this.context = ctx
    },
  },
}
</script>

<style lang="scss">

.card_top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  img {
    width: 100px;
    height: 100px;
  }

  h1 {
    font-size: 2em;
  }

  .badges {
    position: absolute;
    top:0;
    right: 0;
    display: flex;
    gap: 10px;
  }
}

.data-cols {
  text-align: center;

  .design-planning {
    border-top:1px solid #eee;
    padding: 1em;

    .lang {
      height: 1.2em;
      border-radius: 50%;
    }

    h6 {
      font-weight: bold;
    }

    .item-stickers {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2em;
      padding:0 0;
      margin: 0 0;
      list-style: none;

      .item-stickers-item {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: help;

        img {
          max-width: 60px;
          max-height: 60px;
        }
      }
    }
  }
}

.graph .apexcharts-yaxis-texts-g,
.graph .apexcharts-xaxis-texts-g {
  font-size: 0.5em !important;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .small_in_1200_1400 {
    .card {
      .card-body {
        padding: 0.5rem;

        h2 {
          font-size: 1.2rem;
        }
      }
    }
  }
}

</style>
